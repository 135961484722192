import { Image, ImageProps } from '@graphcommerce/image'
import { useCustomerSession } from '@graphcommerce/magento-customer'
import {
  AddProductsToCartButton,
  ProductListItemFragment,
  useProductLink,
} from '@graphcommerce/magento-product'
import { ProductListPrice } from '@graphcommerce/magento-product/components/ProductListPrice/ProductListPrice'
import {
  responsiveVal,
  extendableComponent,
  IconSvg,
  iconCheckmark,
  iconClose,
  iconShoppingBag,
  Button,
} from '@graphcommerce/next-ui'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import {
  ButtonBase,
  Typography,
  Box,
  styled,
  SxProps,
  Theme,
  useEventCallback,
} from '@mui/material'
import { useRouter } from 'next/router'
import React, { PropsWithChildren, useMemo } from 'react'
import { useStoreData } from '../../../../lib/hooks/useStoreData'
import { AMLabelRenderer } from '../../../AMLabel/AMLabelRenderer'
import { ProductSignInButton } from '../../../Product/ProductSignInButton'

const { classes, selectors } = extendableComponent('ProductListItem', [
  'root',
  'item',
  'title',
  'titleContainer',
  'subtitle',
  'price',
  'overlayItems',
  'topLeft',
  'topRight',
  'bottomLeft',
  'bottomRight',
  'imageContainer',
  'placeholder',
  'image',
  'discount',
] as const)

export type OverlayAreaKeys = 'topLeft' | 'bottomLeft' | 'topRight' | 'bottomRight'

export type OverlayAreas = Partial<Record<OverlayAreaKeys, React.ReactNode>>

type StyleProps = {
  aspectRatio?: [number, number]
  imageOnly?: boolean
}

type BaseProps = PropsWithChildren<
  { subTitle?: React.ReactNode } & StyleProps &
    OverlayAreas &
    ProductListItemFragment &
    Pick<ImageProps, 'loading' | 'sizes' | 'dontReportWronglySizedImages'>
>

export type ProductListItemProps = ProductListItemFragment &
  BaseProps & {
    sx?: SxProps<Theme>
    titleComponent?: React.ElementType
    onClick?: (event: React.MouseEvent<HTMLAnchorElement>, item: ProductListItemFragment) => void
    selectedConfigurableImage?: string
  }

const StyledImage = styled(Image)({})

export function ProductListItem(props: ProductListItemProps) {
  const {
    topLeft,
    topRight,
    bottomLeft,
    bottomRight,
    small_image,
    name,
    price_range,
    children,
    loading,
    sizes,
    dontReportWronglySizedImages,
    aspectRatio = [4, 3],
    titleComponent = 'span',
    sx = [],
    sku,
    stock_status,
    brand,
    __typename,
    uid,
    amLabelCategory,
    onClick,
    selectedConfigurableImage,
  } = props

  const handleClick = useEventCallback((e: React.MouseEvent<HTMLAnchorElement>) =>
    onClick?.(e, props),
  )

  const { loggedIn } = useCustomerSession()
  const { mode } = useStoreData()
  const storeB2B = mode === 'b2b'
  const loggedOutB2B = !loggedIn && storeB2B

  const productLink = useProductLink(props)
  const discount = Math.round(price_range.minimum_price?.discount?.percent_off ?? 0)
  const { locale } = useRouter()
  const localeForFormat = locale === 'default' ? 'en' : locale

  const formatter = useMemo(
    () => new Intl.NumberFormat(localeForFormat, { style: 'percent', maximumFractionDigits: 1 }),
    [localeForFormat],
  )

  const label = amLabelCategory?.items?.[0] ?? false
  const showTopLeftLabel = Boolean(topLeft || label || discount)
  const inStock = stock_status === 'IN_STOCK'
  const hasDuplicateLabel = label && label.txt && Math.abs(parseInt(label.txt, 10)) === discount

  return (
    <div key={uid} style={{ height: '100%' }}>
      <ButtonBase
        href={productLink}
        sx={[
          (theme) => ({
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'unset',
            justifyContent: 'unset',
            borderRadius: 0,
            position: 'relative',
            height: '100%',
            backgroundColor: theme.palette.background.paper,
          }),
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
        className={classes.root}
        onClick={onClick ? handleClick : undefined}
      >
        <Box
          sx={(theme) => ({
            display: 'grid',
            backgroundColor: 'background.image',
            padding: responsiveVal(10, 20),
            '& > picture': {
              gridArea: `1 / 1 / 3 / 3`,
            },
            '& .ProductWishlistChipBase-wishlistButton': {
              margin: `calc(-1 * ${responsiveVal(10, 20)})`,
              lineHeight: '1',
              '&:hover': {
                background: `none`,
              },
              '& svg:not(.ProductWishlistChipBase-wishlistIconActive)': {
                fill: theme.palette.background.paper,
              },
            },
          })}
          className={classes.imageContainer}
        >
          {small_image ? (
            <StyledImage
              layout='fill'
              width={1}
              height={1}
              sizes={sizes}
              dontReportWronglySizedImages={dontReportWronglySizedImages}
              src={selectedConfigurableImage || (small_image.url ?? '')}
              alt={small_image.label ?? ''}
              className={classes.image}
              loading={loading}
              sx={{ objectFit: 'contain', aspectRatio: `${aspectRatio[0] / aspectRatio[1]}` }}
            />
          ) : (
            <Box
              sx={{
                gridArea: `1 / 1 / 3 / 3`,
                typography: 'caption',
                display: 'flex',
                textAlign: 'center',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'background.default',
                userSelect: 'none',
              }}
              className={`${classes.placeholder} ${classes.image}`}
            >
              <Trans id='No Image' />
            </Box>
          )}

          <>
            {showTopLeftLabel && (
              <Box
                sx={{
                  gridArea: `1 / 1 / 2 / 2`,
                  zIndex: 1,
                }}
                className={classes.topLeft}
              >
                {label && <AMLabelRenderer label={label} />}
                {discount > 0 && !hasDuplicateLabel && (
                  <Box
                    className={classes.discount}
                    sx={{
                      typography: 'caption',
                      bgcolor: 'text.primary',
                      fontWeight: 'fontWeightBold',
                      border: 1,
                      borderColor: 'divider',
                      borderRadius: '3px',
                      padding: '0px 6px',
                      color: 'background.default',
                      display: 'inline-block',
                    }}
                  >
                    {formatter.format(discount / -100)}
                  </Box>
                )}
                {topLeft}
              </Box>
            )}
            {topRight && (
              <Box
                sx={{
                  justifySelf: 'end',
                  textAlign: 'right',
                  gridArea: `1 / 2 / 2 / 3`,
                  zIndex: 1,
                }}
                className={classes.topRight}
              >
                {topRight}
              </Box>
            )}
            {bottomLeft && (
              <Box
                sx={{
                  alignSelf: 'flex-end',
                  gridArea: `2 / 1 / 3 / 2`,
                  zIndex: 1,
                }}
                className={classes.bottomLeft}
              >
                {bottomLeft}
              </Box>
            )}
            {bottomRight && (
              <Box
                sx={{
                  textAlign: 'right',
                  alignSelf: 'flex-end',
                  gridArea: `2 / 2 / 3 / 3`,
                  zIndex: 1,
                  justifySelf: 'end',
                }}
                className={classes.bottomRight}
              >
                {bottomRight}
              </Box>
            )}
          </>
        </Box>
        <svg
          fill='none'
          height='24'
          viewBox='0 0 24 24'
          width='24'
          xmlns='http://www.w3.org/2000/svg'
          style={{ position: 'absolute' }}
        >
          <defs>
            <clipPath id='myCurve'>
              <path d='m5.81956 20.3965s9.60344 8.7128 16.40094-.9562c2.7097-4.6187.931-14.63216-3.5976-17.42672-3.2025-2.751662-16.76684-.36332-17.892481 3.48189-1.533469 7.36313 5.089141 14.90103 5.089141 14.90103z' />
            </clipPath>
          </defs>
        </svg>
        <>
          <Box
            className={classes.titleContainer}
            sx={(theme) => ({
              padding: `0 ${responsiveVal(10, 20)}`,
              display: 'grid',
              gridTemplateColumns: 'unset',

              rowGap: 1,
              columnGap: 2,
              gridTemplateAreas: `
                  "title"
                  "brand"
                  "sku"
                `,
              [theme.breakpoints.up('md')]: {
                gridTemplateAreas: `
                    "title title"
                    "brand sku"
                  `,
                gridTemplateColumns: 'auto 1fr',
              },
            })}
          >
            <Typography
              component={titleComponent}
              variant='h5'
              sx={{
                display: 'inline',
                color: 'text.primary',
                overflowWrap: 'break-word',
                wordBreak: 'break-word',
                maxWidth: '100%',
                gridArea: 'title',
                fontWeight: 'fontWeightBold',
                lineHeight: 1.3,
              }}
              className={classes.title}
            >
              {name}
            </Typography>
            <Box
              sx={() => ({
                gridArea: 'brand',
                color: 'text.secondary',
                typography: 'body1',
              })}
            >
              {brand?.label}
            </Box>
            <Box sx={{ gridArea: 'sku', color: 'text.disabled', typography: 'body1' }}>{sku}</Box>
          </Box>
          {children}
        </>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: responsiveVal(10, 20),
            marginTop: 'auto',
            gap: 1,
            flexWrap: 'wrap',
          }}
        >
          {loggedOutB2B ? (
            <ProductSignInButton />
          ) : (
            <ProductListPrice
              {...price_range.minimum_price}
              sx={{
                textAlign: 'right',
                justifySelf: { sm: 'flex-end' },
                color: 'primary.main',
                typography: 'h5',
              }}
            />
          )}
          {__typename !== 'ConfigurableProduct' && (
            <div>
              {name && inStock && !loggedOutB2B && (
                <AddProductsToCartButton
                  sku={sku}
                  size='medium'
                  variant='contained'
                  color='secondary'
                  sx={(theme) => ({
                    px: theme.spacings.xxs,
                  })}
                  aria-label={i18n._(/* i18n */ 'Add to Cart')}
                >
                  <IconSvg size='large' src={iconShoppingBag} />
                </AddProductsToCartButton>
              )}
            </div>
          )}
          {__typename === 'ConfigurableProduct' && (
            <div>
              <Button
                variant='contained'
                color='secondary'
                size='small'
                sx={{
                  fontSize: responsiveVal(14, 16),
                  height: responsiveVal(38, 44),
                  fontWeight: 600,
                  lineHeight: 1.1,
                }}
              >
                <Trans id='Select options' />
              </Button>
            </div>
          )}
        </Box>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: `min-content auto`,
            alignItems: 'center',
            gap: responsiveVal(5, 10),
            padding: responsiveVal(10, 20),
          }}
        >
          <IconSvg
            src={inStock ? iconCheckmark : iconClose}
            sx={
              inStock
                ? {
                    transform: 'translateY(-2px)',
                    color: 'secondary.main',
                    alignSelf: 'start',
                  }
                : { color: 'text.main', alignSelf: 'start' }
            }
          />
          <Typography
            variant='caption'
            component='span'
            color={inStock ? 'secondary' : 'text'}
            sx={{ lineHeight: 1.2 }}
          >
            {inStock ? (
              <Trans id='Shipped within 2-3 days' />
            ) : (
              <Trans id='Temporarily not available' />
            )}
          </Typography>
        </Box>
      </ButtonBase>
    </div>
  )
}

ProductListItem.selectors = { ...selectors, ...ProductListPrice.selectors }
